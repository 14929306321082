.item {
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    max-width: 360px;
    width: 100%;

    color: #fff;

    .image {
        width: 100%;
        //max-width: 360px;
    }

    .description {
        display: flex;
        justify-content: space-between;
        text-wrap: wrap;
        margin: 4px;

    }
}

.sold {
    text-align: right;
    font-size: smaller;
    color: #d9d5d5;
}
