.container {
    max-width: 1200px;
    margin: 0 10px 20px 10px;
}

.item {
    display: flex;
    justify-content: center;

    .image {
        width: 60%;
        height: 60%;
        max-width: 700px;
    }


}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-size: calc(10px + 1.6vmin);
        word-wrap: normal;

        width: 230px;
        height: 230px;

        .back {
            position: absolute;
            opacity: 0.2;
            width: 230px;
            height: 230px;
            border-radius: 5px;
            overflow: hidden;

            .backImage {
                width: 100%;
                height: 100%;
            }
        }

        .label {
            z-index: 1;
            text-align: center;
        }

        .buttonSeeProject {
            margin-top: 20px;
            min-width: 200px;
            color: #f9b934;
            border: 1px solid rgba(249, 185, 52, 0.5);
        }
    }
}

@media screen and (max-width: 660px) {
    .item {
        flex-flow: column;
        align-items: center;
        .image {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .info {
        width: auto;
    }
}

@media screen and (min-width: 661px) {
    .item {
        &.reverse {
            flex-flow: row-reverse;
        }
    }
}
