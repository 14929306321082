
.toolbar {
    display: flex;
    justify-content: center;
    gap: 8px;

    .name {
        font-family: Lato;
        line-height: 1.167;

        cursor: default;

        .author {
            color: #ffffff;
        }
    }

    .bubble {
        flex-grow: 1;
    }
}

.contacts {
    min-width: 60px;
    display: flex;
    justify-content: space-between;

    .email {
        color: #d9d5d5;
        cursor: pointer;
    }

    .telegram {
        color: #d9d5d5;
    }
}
