
.container {
    display: flex;
    justify-content: center;
    width: 100%;
    color: #fff;
    background-color: #424242;

    .content {
        max-width: 1200px;
    }

    .divider {
        background-color: rgba(255, 255, 255, 0.12);
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        gap: 1rem;
        margin-top: 1rem;
    }
}
