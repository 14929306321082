.container {
    width: 100%;
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .placeholder {
        height: 100%;
        //line-height: 1px;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .imageBox {
            margin: 10px 10px 0 10px;

            .image {
                max-width: 100%;
                min-width: 300px;
                max-height: 100vh;
            }

            //.watermark {
            //    position: absolute;
            //    transform: translate(-100%);
            //    max-width: calc(100% - 20px);
            //    min-width: 300px;
            //    max-height: 100vh;
            //    opacity: 0.2;
            //}
        }

        .loader {
            background: url("../assets/loader.gif") center center no-repeat;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;

            &.loaderHidden {
                display: none;
            }
        }

        .magnifier {
            text-decoration: none;
            opacity: 1;
            position: relative;
            height: auto;
            display: block;
            transition: all 0.3s ease;

            .image {
                width: 1200px;
                height: auto;
            }
        }
    }

    .buttonSeeProject {
        margin-top: 10px;
        min-width: 200px;
        color: #f9b934;
        border: 1px solid rgba(249, 185, 52, 0.5);
    }
}

.panoLink {
    cursor: pointer;
    text-decoration: underline;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #90caf9;
    text-transform: uppercase;
    font-weight: 500;
}
