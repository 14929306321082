.container {
    right: 20px;
    bottom: 20px;
    position: fixed;
    z-index: 1;
    font-size: 2.6em;

    &.hidden {
        display: none;
    }

    color: #f9b934;
    cursor: pointer;
    transition: opacity 0.4s;
    opacity: 0.6;

    transform: rotate(180deg);
}

.container:hover{
    opacity: 0.9;
}
