$colorYellow: #f9b934;
$colorBlue: #90caf9;

.toolbar {
    display: flex;
    justify-content: center;

    .name {
        font-family: Lato;
        line-height: 1.167;

        cursor: default;

        .pageLabel {
            color: #ffffff;
        }

        .author {
            color: $colorYellow;
        }
    }

    .bubble {
        flex-grow: 1;
    }

    .buttonSwitch {
        color: rgba(0, 0, 0, 0.87);
        min-width: 260px;
        white-space: nowrap;
        margin: 5px;

        &.modeling {
            background-color: $colorBlue;
        }

        &.design {
            background-color: $colorYellow;
        }

        &.disabled {
            opacity: 0.7;
        }
    }
}

@media screen and (max-width: 768px){
    .toolbar {
        flex-wrap: wrap;
    }

    @media screen and (min-width: 440px) {
        .bubble {
            min-width: 100px;
        }
    }

    @media screen and (min-width: 550px){
        .bubble {
            min-width: 200px;
        }
    }
}

@media screen and (max-width: 1120px) {
    .pageLabel {
        display: none;
    }
}

.contacts {
    min-width: 100px;
    display: flex;
    justify-content: space-between;

    .email {
        color: #d9d5d5;
        cursor: pointer;
    }

    .instagram {
        color: #d9d5d5;
    }

    .telegram {
        color: #d9d5d5;
    }
}

.divider {
    width: 10px;
}
