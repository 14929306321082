
.bottom {
    color: rgba(255, 255, 255, 0.5);
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .linkPrivacyPolicy {
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
    }

    .text {
        margin: 5px;
    }

    .delimiter {
        width: 20px;
        text-align: center;
    }
}

@media screen and (max-width: 420px) {
    .bottom {
        .text {
            margin: 5px 20px;
        }
        .delimiter {
            display: none;
        }
    }
}
