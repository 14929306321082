.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .otherProjectsBlock {
        .otherProjectsButton {
            margin: 20px 0 40px 0;
            min-width: 320px;
            background-color: #f9b934;
        }
    }

    .description {
        padding: 0 4px;
        text-align: justify;
        line-height: 1.2rem;
    }

    .divider {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.12);
        margin: 4px 0;
    }

    .projects {
        margin-top: 22px;
    }
}
